/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import Consent from './consent';
import Footer from './footer';

interface Props {
  className?: string;
}

const Layout: React.FC<Props> = ({ children, className = '' }) => {
  return (
    <>
      <main className={`min-h-screen ${className}`}>{children}</main>
      <Footer />
      <Consent />
    </>
  );
};

export default Layout;
