import React from 'react';

const Footer: React.FC = () => (
  <footer
    style={{ bottom: '1rem' }}
    className="text-gray-300 text-center mt-2 absolute left-0 right-0"
  >
    © {new Date().getFullYear()} WinterCell Studios
  </footer>
);

export default Footer;
