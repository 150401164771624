import React from 'react';
import CookieConsent from 'react-cookie-consent';

const Consent: React.FC = () => (
  <CookieConsent
    enableDeclineButton
    location="bottom"
    buttonText="Accept"
    declineButtonText="Decline"
    cookieName="gatsby-gdpr-google-analytics"
    containerClasses="flex flex-wrap justify-between fixed w-full bottom-0 z-50 items-baseline text-gray-300  max-w-screen-lg mx-auto left-0 right-0 bg-black"
    contentStyle={{ flexBasis: '250px' }}
    contentClasses="m-4 flex-1"
    buttonWrapperClasses="ml-auto"
    buttonClasses="m-4 px-3 py-1 border-0 cursor-pointer bg-yellow-500 hover:bg-yellow-400 text-gray-800"
    declineButtonClasses="m-2 px-3 py-1 border-0 cursor-pointer text-gray-500"
    disableStyles
    onAccept={() => {
      window.location.reload();
    }}
  >
    We use cookies to give you the best online experience. Please let us know if
    you agree.
  </CookieConsent>
);

export default Consent;
