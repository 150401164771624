/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import * as jsonLd from './json-ld.json';

interface MetaDataName {
  name: string;
  content: unknown;
  property: undefined;
}

interface MetaDataDescription {
  name: undefined;
  content: unknown;
  property: string;
}

interface Props {
  description?: string;
  lang?: string;
  meta?: MetaDataName | MetaDataDescription[];
  title: string;
}

const SEO: React.FC<Props> = ({
  description = '',
  lang = 'en',
  meta = [],
  title,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            url
            title
            description
            author
            twitterUsername
            imagePath
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;
  const defaultTitle = site.siteMetadata?.title;
  const metaImagePath = `${site.siteMetadata.url}${site.siteMetadata.imagePath}`;
  const metaImageAlt = `SoulHunt logo`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={defaultTitle ? `${defaultTitle}: %s` : null}
      meta={[
        {
          name: 'google-site-verification',
          content: 'TKphPFN3gh5wIurBod8MLstPCTgvvy9HjWjxWuvQYqM',
        },
        {
          name: `og:url`,
          content: site.siteMetadata.url,
        },
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: 'og:image',
          content: metaImagePath,
        },
        {
          property: 'og:image:alt',
          content: metaImageAlt,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: 'twitter:creator',
          content: site.siteMetadata?.twitterUsername || ``,
        },
        {
          name: `twitter:site`,
          content: site.siteMetadata?.twitterUsername || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          property: 'twitter:image',
          content: metaImagePath,
        },
        {
          property: 'twitter:image:alt',
          content: metaImageAlt,
        },
      ].concat(meta)}
    >
      <script type="application/ld+json">{JSON.stringify(jsonLd)}</script>
      <body className="bg-black" />
    </Helmet>
  );
};

export default SEO;
